import { io } from 'socket.io-client';
import { isObject } from 'lodash';

// function createTraceableObject() {
//   const error = new Error();
//   // console.log(error.stack?.split('\n'));
//   const stackLine = error.stack?.split('\n')[2];
//
//   const traceInfo = stackLine?.match(/at\s+(?<funcao>.*)\((?<arquivo>.*):(?<linha>\d+):(?<coluna>\d+)\)/);
//
//   const funcao = traceInfo ? traceInfo.groups.funcao : 'Unknown';
//   const arquivo = traceInfo ? traceInfo.groups.arquivo : 'Unknown';
//   const linha = traceInfo ? traceInfo.groups.linha : 'Unknown';
//   const coluna = traceInfo ? traceInfo.groups.coluna : 'Unknown';
//
//   return {
//     funcao,
//     arquivo,
//     linha: `${linha}:${coluna}`,
//     timestamp: new Date(),
//   };
// }

export function socketConnection(params) {
  let userId = null;
  if (localStorage.getItem('userId')) {
    userId = localStorage.getItem('userId');
  }
  return io(process.env.REACT_APP_BACKEND_URL, {
    transports: ['websocket', 'polling', 'flashsocket'],
    pingTimeout: 18000,
    pingInterval: 18000,
    query: isObject(params) ? { ...params } : { userId: params.userId },
  });
}
